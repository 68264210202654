<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Payment Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="vendor"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="poCode"
          readonly
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouseName"
          readonly
        />
      </div>
    </div> -->
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Date"
          name="Invoice Date"
          v-model="table.inv_date"
          readonly
        />
      </div>
    </div>
    <!-- <div v-if="createdDate != 'January 1, 0001'" class="vx-col w-full mb-2">
      <vs-input
        class="w-full input-readonly"
        label="Created Date"
        name="Created Date"
        v-model="createdDate"
        readonly
      />
    </div> -->
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Term"
          name="Payment Term"
          v-model="table.payment_term_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Bank Name"
          name="Bank Name"
          v-model="table.bank_name"
          readonly
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Number"
          name="Tax Return Number"
          v-model="tr_number"
          placeholder="Tax Return Number"
          readonly
        />
      </div>
    </div> -->
    <!-- <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Date"
          name="Tax Return Date"
          v-model="tr_date"
          placeholder="Tax Return Date"
          readonly
        />
      </div>
    </div> -->
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qty Inv</vs-th>
        <vs-th>Total Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span class="w-5/5"
                >Received :
                {{
                  data[indextr].qty_received
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ data[indextr].item_unit }}</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].quantity }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].net }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].discount }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].subtotal }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 mt-4 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].value }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  <vs-table stripe border description class="w-full" :sst="true" :data="table.data_payment">
      <template slot="thead">
        <vs-th style="text-align: center;">Payment Account</vs-th>
        <vs-th style="text-align: center;">Payment Voucher</vs-th>
        <vs-th style="text-align: right;">Paid</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td style="text-align: center;" :data="data[indextr].BankName">
            {{ data[indextr].BankID == 0? '-': (data[indextr].BankName + ' '  + data[indextr].AccountNumber) }}
            
          </vs-td>
          <vs-td style="text-align: center;">
              {{ data[indextr].ReferenceCode}}
            
          </vs-td>
          <vs-td style="text-align: right;">
            {{ data[indextr].PaidInvoiceValue.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value"
        name="Item Value"
        v-model="table.item_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value"
        name="Charges Value"
        v-model="table.charges_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value"
        name="Tax Value"
        v-model="table.tax_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value"
        name="Discount Value"
        v-model="table.discount_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value"
        name="Total Invoice Value"
        v-model="table.total_inv_value"
        readonly
      />
    </div>
    <div class="horizontal-divider text-center"></div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Total Paid"
          name="Total Paid"
          v-model="total_paid"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Total Unpaid"
          name="Total Unpaid"
          v-model="total_unpaid"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <label class="vs-input--label">Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="bank"
          :options="optionBankName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.name ? dt.option.name : dt.option.Name
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.name ? dt.option.name : dt.option.Name
              }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-if="bank == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Account Number"
          name="Account Number"
          v-model="account_number"
          placeholder="Account Number"
        />
        <span
          class="text-danger text-sm"
          v-if="account_number == null && status_submit"
          >This field is required</span
        >
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Total Value"
          name="total Value"
          v-model="total_value"
          @input="formatNumber()"
        />
        <span
          class="text-danger text-sm"
          v-if="total_value == 0 && status_submit"
          >This field is required</span
        >
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Payment Voucher"
          name="Payment Voucher"
          v-model="reference_code"
        />
        <span
          class="text-danger text-sm"
          v-if="reference_code == '' && status_submit"
          >This field is required</span
        >
      </div>
<div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Notes"
          name="Notes"
          placeholder="Notes"
          v-model="notes"
        />
      </div>
    </div>
      <!-- <vs-divider style="width: 100%; margin-left: 2%">Debit Note</vs-divider>
      <multiselect
        class="selectExample"
        v-model="debitNote"
        :options="optionDebitNote"
        :multiple="true"
        :max="maxSelectedDN"
        @remove="removeSelectedDN"
        @input="selectedDN"
        :allow-empty="true"
        :group-select="false"
        :max-height="160"
        :limit="5"
        placeholder=" Type to search"
        track-by="ID"
        label="Code"
        :disabled="false"
      >
        <template slot="singleLabel" slot-scope="dt">
          <span class="option__desc">
            <span class="option__title">{{ dt.option.Code }}</span>
          </span>
        </template>

        <template slot="option" slot-scope="dt">
          <div class="option__desc">
            <span class="option__title">{{
              dt.option.Code +
              " : " +
              parseFloat(dt.option.TotalDnValue - dt.option.UsedDnValue).toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ) 
            }}</span>
          </div>
        </template>
      </multiselect>
      <div v-if="debitNote.length > 0">
        <vs-list>
          <vs-list-item
            v-for="item in debitNote"
            :key="item.Code"
            :title="item.Code"
            :subtitle="
              parseFloat(item.TotalDnValue - item.UsedDnValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
          ></vs-list-item>
        </vs-list>
        <div class="vx-col sm:w-3/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleApplyDebitNote"
              >Apply Debit Note</vs-button
            >
          </div>
        </div>
      </div> -->
      <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              accept=".jpg, .jpeg, .png"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr, i) in table.file">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.name"
            >
              <td class="td vs-table--td">{{ tr.doc_type }}</td>
              <td class="td vs-table--td">{{ tr.name }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="downloadFileAwsS3(tr.path)"
                    icon="icon-eye"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      @click.stop="handleDeleteAttachment(i, tr.id)"
                      icon="icon-trash"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>

      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full" style="margin-bottom: 150px">
          <vs-button class="mb-2" @click="handleSubmit"
            >Confirm Payment</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    paymentId: {
      type: Number,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        debitNote: [],
        optionDebitNote: [],
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        maxSelectedDN: 999,
        reference_code:"",
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        bank: null,
        total_paid: 0,
        total_unpaid: 0,
        account_number: null,
        total_value: 0,
        notes:"",
        file: {},
      };
    },
    formatNumber() {
      if (
        Number(this.total_value.toString().replace(/,/g, "")) >
        Number(this.total_unpaid.toString().replace(/,/g, ""))
      ) {
        this.$vs.notify({
          color: "warning",
          title: "Information",
          text: "Check total unpaid.",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.total_value = this.total_paid
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        this.total_value = Number(this.total_value.toString().replace(/,/g, ""))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.doSubmit();
        }
      });
    },
    handleDeleteAttachment(index, id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
      // console.log(this.table.file);
    },
    handleAttachment() {
      console.log(this.$refs.file.files[0]);
      if (this.$refs.file.files[0]) {
        // this.table.file.push({
        //   name: this.$refs.file.files[0].name,
        //   file: this.$refs.file.files[0],
        //   isNew: true,
        //   path: "",
        // });
        this.file = this.$refs.file.files[0];
        document.querySelector("#fileInput").value = "";
        this.uploadData();
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        window.open(selected.path);
      }
    },
    paramUpload() {
      var form = new FormData();
      form.append("pi_id", this.piId);
      form.append("file", this.file);
      form.append("type", this.file.type);
      return form;
    },
    uploadData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.table.file.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    doSubmit() {
      let totalValue = parseFloat(this.total_value.toString().replace(/,/g,''))
      let param = {
        // pi_id: this.piId,
        // inv_code: this.invCode,
        // bank_id: this.bank.id ? this.bank.id : this.bank.ID,
        // bank_name: this.bank.name ? this.bank.name : this.bank.Name,
        // account_number: this.account_number,
        // total_value: this.total_value,
        // total_paid: this.total_paid,
        purchase_payment_id: this.paymentId,
        purchase_id: this.piId,
        paid: totalValue,
        reference_code: this.reference_code,
      };
      if (parseFloat(this.total_unpaid.toString().replace(/,/g,'')) > totalValue) {
        console.log('partial')
        this.$http
          .post("/api/v1/pi/partial-payment", param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        param = {
          purchase_id: this.piId,
          purchase_payment_id: this.paymentId,
          reference_code: this.reference_code,
          status_payment: 4,
          status: "13",
        };
        this.$http
          .post("/api/v1/pi/change-status-payment", param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
          console.log('no partial')
      }
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getOptionBankName() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/bank", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionBankName = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Bank Name option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleApplyDebitNote() {
      console.log('apply dn', this.debitNote)
      const debitNote = []
      this.debitNote.map((v) => {
        debitNote.push(v.ID)
      })
      let param = {
        invoice_id: this.piId,
        debit_note: debitNote
      };
      this.$http
        .post("/api/v1/pi/apply-debit-note", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/payment-proposal-line-with-payment/" + this.piId + "/" + this.paymentId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.inv_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.inv_date))).format("DD/MM/YYYY")
                : null;
            this.table.payment_term_name = resp.data.payment_term_name;
             this.bank = {
              id: resp.data.bank_id_proposal,
              name: resp.data.bank_name_proposal,
            };
            this.vendor = resp.data.supplier_name
            this.account_number = resp.data.account_number_proposal
            this.table.bank_name =
              resp.data.bank_name +
              " - " +
              resp.data.account_number +
              " " +
              resp.data.account_name;
            this.tr_date =
              moment(String(resp.data.tr_date)).format("YYYY-MM-DD") ==
              "0001-01-01"
                ? ""
                : moment(String(new Date(resp.data.tr_date))).format("DD/MM/YYYY");
            this.tr_number = resp.data.tr_number;
            this.table.discount_value = resp.data.total_discount
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // paid value belum confirm jadi switch to unpaid 
            this.total_paid = resp.data.unpaid_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total_unpaid = resp.data.paid_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total_value = resp.data.unpaid_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.total_inv_value = resp.data.total_invoice_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value = resp.data.item_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.charges_value = resp.data.charge_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.optionDebitNote = resp.data.debit_note;
            this.table.po_code = resp.data.po_code;
            this.table.vendor = resp.data.vendor;

            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.table.data.push({
                net: element.net
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity: element.quantity
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount: element.discount
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                qty_received: element.qty_received,
                subtotal: (element.net - element.discount)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
            });

            resp.data.data_charge.forEach(function (element, index) {
              _this.table.data_charges.push({
                value: element.value
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                id: element.id,
                charge_name: element.charge_name,
                tax_rate: element.tax_rate,
              });
            });

            resp.data.data_attachment.map((r, i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path,
                doc_type: r.doc_type,
              });
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    removeSelectedDN() {
      this.maxSelectedDN = 999
    },
    selectedDN(selectedOption, id) {
      console.log(selectedOption, id)
      console.log(this.total_unpaid)
      const totalSelectedDebitNote = this.debitNote.map(item => {
        console.log(item)
        
          return item.TotalDnValue
        
      }).reduce((prev, curr) => prev + curr, 0)
      console.log(this.debitNote.length)
      console.log(parseFloat(this.total_unpaid.toString().replace(/,/g,"")) < totalSelectedDebitNote,parseFloat(this.total_unpaid.toString().replace(/,/g,"")))
      if (parseFloat(this.total_unpaid.toString().replace(/,/g,""))  < totalSelectedDebitNote) {
        this.maxSelectedDN = this.debitNote.length
      }
    }
  },
  mounted() {
    if (this.piId) {
      this.getData();
      this.getOptionBankName();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.getOptionBankName();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>